import React, { useState, useEffect } from "react"

import GeneralFilter from "./general/generalFilter"
import StatsFilter from "./stats/statsFilter"

const filterChoices = {
  GENERAL: 0,
  PARTS: 1,
  STATS: 2,
}

const Filter = ({
  toggleFilterCallback = () => {},
  filterCallback = () => {},
}) => {
  const [showFilter, setShowFilter] = useState(true)

  useEffect(() => {
    setShowFilter(true)
  }, [showFilter])

  const handleClearFilter = () => {
    setShowFilter(false)
    setNumFilters(0)
    setNumStatFilters(0)
    setNumGenFilters(0)
    setGeneralFilterData(null)
    setStatFilterData(null)
  }

  const [numFilters, setNumFilters] = useState(0)
  const [numStatFilters, setNumStatFilters] = useState(0)

  const [numGenFilters, setNumGenFilters] = useState(0)

  const [currentFilterChoice, setCurrentFilterChoice] = useState(
    filterChoices.GENERAL
  )

  const [generalFilterData, setGeneralFilterData] = useState()

  //null indicates no filter
  const [statFilterData, setStatFilterData] = useState()

  useEffect(() => {
    setNumFilters(numGenFilters + numStatFilters)
  })

  useEffect(() => {
    //send back object of filter objects
    let tmp = {
      general: generalFilterData,
      stat: statFilterData,
    }
    filterCallback(tmp)
  }, [generalFilterData, statFilterData])

  const handleFilterChoice = choice => {
    if (choice === currentFilterChoice) {
      return //No need to do anything
    }
    switch (choice) {
      case filterChoices.GENERAL:
        setCurrentFilterChoice(filterChoices.GENERAL)
        break
      case filterChoices.PARTS:
        setCurrentFilterChoice(filterChoices.PARTS)
        break
      case filterChoices.STATS:
        setCurrentFilterChoice(filterChoices.STATS)
        break
    }
  }

  const updateStatsCallback = statData => {
    setStatFilterData(statData)

    //update number of filters applied
    let numStatSelected = 0
    if (statData.health) {
      numStatSelected++
    }
    if (statData.speed) {
      numStatSelected++
    }
    if (statData.skill) {
      numStatSelected++
    }
    if (statData.morale) {
      numStatSelected++
    }
    setNumStatFilters(numStatSelected)
  }

  const updateGeneralCallback = generalData => {
    setGeneralFilterData(generalData)

    let numGenSelected = 0
    if (generalData.borrow.slp || generalData.borrow.eth) {
      numGenSelected++
    }
    if (
      generalData.class.beast ||
      generalData.class.aquatic ||
      generalData.class.plant ||
      generalData.class.bird ||
      generalData.class.bug ||
      generalData.class.reptile ||
      generalData.class.mech ||
      generalData.class.dawn ||
      generalData.class.dusk
    ) {
      numGenSelected++
    }
    if (generalData.mystic > 0) {
      numGenSelected++
    }
    if (generalData.pureness > 0) {
      numGenSelected++
    }
    if (
      generalData.other.japan ||
      generalData.other.meo ||
      generalData.other.meo2 ||
      generalData.other.origin
    ) {
      numGenSelected++
    }

    setNumGenFilters(numGenSelected)

    return
  }

  return (
    <div className="w-4/5 px-16 md:w-full filter text-white absolute md:static overflow-y-auto overscroll-none mb-24 z-40">
      <div className="w-full flex flex-row justify-between items-center">
        <h2 className="title text-axietree-accent text-left">
          Filter ({numFilters})
        </h2>
        <h4
          className="title cursor-pointer hover:underline text-secondary-text"
          onClick={handleClearFilter}
        >
          Clear Filter
        </h4>
      </div>
      <div className="w-full filter-select text-axietree-accent">
        <button
          className={
            (currentFilterChoice === filterChoices.GENERAL
              ? "bg-axietree-accent text-card-bg"
              : "bg-card-bg text-axietree-accent") + " general w-1/2"
          }
          onClick={() => handleFilterChoice(filterChoices.GENERAL)}
        >
          <p>General ({numGenFilters})</p>
        </button>
        {/* <button
          className={
            (currentFilterChoice === filterChoices.PARTS
              ? "bg-axietree-accent text-card-bg"
              : "bg-card-bg text-axietree-accent") + " parts"
          }
          onClick={() => handleFilterChoice(filterChoices.PARTS)}
        >
          <p>Parts (0)</p>
        </button> */}
        <button
          className={
            (currentFilterChoice === filterChoices.STATS
              ? "bg-axietree-accent text-card-bg"
              : "bg-card-bg text-axietree-accent") + " stats w-1/2"
          }
          onClick={() => handleFilterChoice(filterChoices.STATS)}
        >
          <p>Stats ({numStatFilters})</p>
        </button>
      </div>
      <div
        className={
          (currentFilterChoice === filterChoices.GENERAL ? "" : "hidden") +
          " w-full"
        }
      >
        {showFilter && (
          <GeneralFilter updateGeneralCallback={updateGeneralCallback} />
        )}
      </div>
      <div
        className={
          (currentFilterChoice === filterChoices.STATS ? "" : "hidden") +
          " w-full"
        }
      >
        {showFilter && (
          <StatsFilter updateStatsCallback={updateStatsCallback} />
        )}
      </div>
      <div className="md:hidden my-32"></div>
      <div className="md:hidden fixed bottom-0 w-4/5 px-16 py-12 bg-background">
        <button
          className="py-12 bg-axietree-accent text-background w-full rounded-md"
          onClick={toggleFilterCallback}
        >
          <h3>Apply Filters</h3>
        </button>
      </div>
    </div>
  )
}

export default Filter
