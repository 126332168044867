import React from "react"

const Checkbox = ({ text, isChecked, onClick = () => {}, children }) => (
  <label className="flex items-center">
    <input
      type="checkbox"
      defaultChecked={isChecked}
      className="text-axietree-accent bg-transparent border-axietree-accent"
      onClick={onClick}
    />
    {children}
    <span className="ml-2">{text}</span>
  </label>
)

export default Checkbox
