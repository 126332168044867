import React, { useState } from "react"

import { classIcon } from "../../../utils"

import Checkbox from "../../../components/checkbox"
import Range from "./range/range"

const borrowType = {
  SLP: 0,
  ETH: 1,
}

const classType = {
  BEAST: 0,
  AQUATIC: 1,
  PLANT: 2,
  BIRD: 3,
  BUG: 4,
  REPTILE: 5,
  MECH: 6,
  DAWN: 7,
  DUSK: 8,
}

const otherType = {
  JAPAN: 0,
  MEO: 1,
  MEO2: 2,
  ORIGIN: 3,
}

const GeneralFilter = ({ updateGeneralCallback = () => {} }) => {
  const [generalFilterData, setGeneralFilterData] = useState({
    borrow: {
      eth: false,
      slp: false,
    },
    class: {
      beast: false,
      aquatic: false,
      plant: false,
      bird: false,
      bug: false,
      reptile: false,
      mech: false,
      dawn: false,
      dusk: false,
    },
    mystic: 0,
    pureness: 0,
    other: {
      japan: false,
      meo: false,
      meo2: false,
      origin: false,
    },
  })

  const handleBorrow = (e, type) => {
    let tmp = JSON.parse(JSON.stringify(generalFilterData))
    switch (type) {
      case borrowType.SLP:
        tmp.borrow.slp = e.target.checked
        break
      case borrowType.ETH:
        tmp.borrow.eth = e.target.checked
        break
    }

    setGeneralFilterData(tmp)
    updateGeneralCallback(tmp)
  }

  const handleClass = (e, type) => {
    let tmp = JSON.parse(JSON.stringify(generalFilterData))
    switch (type) {
      case classType.BEAST:
        tmp.class.beast = e.target.checked
        break
      case classType.AQUATIC:
        tmp.class.aquatic = e.target.checked
        break
      case classType.PLANT:
        tmp.class.plant = e.target.checked
        break
      case classType.BIRD:
        tmp.class.bird = e.target.checked
        break
      case classType.BUG:
        tmp.class.bug = e.target.checked
        break
      case classType.REPTILE:
        tmp.class.reptile = e.target.checked
        break
      case classType.MECH:
        tmp.class.mech = e.target.checked
        break
      case classType.DAWN:
        tmp.class.dawn = e.target.checked
        break
      case classType.DUSK:
        tmp.class.dusk = e.target.checked
        break
    }

    setGeneralFilterData(tmp)
    updateGeneralCallback(tmp)
  }

  const handleMystic = val => {
    let tmp = JSON.parse(JSON.stringify(generalFilterData))
    tmp.mystic = val

    setGeneralFilterData(tmp)
    updateGeneralCallback(tmp)
  }

  const handlePureness = val => {
    let tmp = JSON.parse(JSON.stringify(generalFilterData))
    tmp.pureness = val

    setGeneralFilterData(tmp)
    updateGeneralCallback(tmp)
  }

  const handleOther = (e, type) => {
    let tmp = JSON.parse(JSON.stringify(generalFilterData))
    switch (type) {
      case otherType.JAPAN:
        tmp.other.japan = e.target.checked
        break
      case otherType.MEO:
        tmp.other.meo = e.target.checked
        break
      case otherType.MEO2:
        tmp.other.meo2 = e.target.checked
        break
      case otherType.ORIGIN:
        tmp.other.origin = e.target.checked
        break
    }

    setGeneralFilterData(tmp)
    updateGeneralCallback(tmp)
  }

  return (
    <>
      <div className="mb-8 mt-16 w-full">
        <p className="text-left text-secondary-text">BORROW TYPE</p>
        <div className="grid grid-cols-2 gap-3 mt-8">
          <Checkbox
            text="% SLP"
            isChecked={false}
            onClick={e => handleBorrow(e, borrowType.SLP)}
          />
          <Checkbox
            text="ETH"
            isChecked={false}
            onClick={e => handleBorrow(e, borrowType.ETH)}
          />
        </div>
      </div>
      <div className="my-8 w-full">
        <p className="text-left text-secondary-text">CLASS</p>
        <div className="grid grid-cols-2 gap-3 mt-8">
          <Checkbox
            text="Beast"
            isChecked={false}
            onClick={e => handleClass(e, classType.BEAST)}
          >
            <img className="ml-8" src={classIcon.beast} alt="beast" />
          </Checkbox>
          <Checkbox
            text="Aquatic"
            isChecked={false}
            onClick={e => handleClass(e, classType.AQUATIC)}
          >
            <img className="ml-8" src={classIcon.aquatic} alt="aquatic" />
          </Checkbox>
          <Checkbox
            text="Plant"
            isChecked={false}
            onClick={e => handleClass(e, classType.PLANT)}
          >
            <img className="ml-8" src={classIcon.plant} alt="plant" />
          </Checkbox>
          <Checkbox
            text="Bird"
            isChecked={false}
            onClick={e => handleClass(e, classType.BIRD)}
          >
            <img className="ml-8" src={classIcon.bird} alt="bird" />
          </Checkbox>
          <Checkbox
            text="Bug"
            isChecked={false}
            onClick={e => handleClass(e, classType.BUG)}
          >
            <img className="ml-8" src={classIcon.bug} alt="bug" />
          </Checkbox>
          <Checkbox
            text="Reptile"
            isChecked={false}
            onClick={e => handleClass(e, classType.REPTILE)}
          >
            <img className="ml-8" src={classIcon.reptile} alt="reptile" />
          </Checkbox>
          <Checkbox
            text="Mech"
            isChecked={false}
            onClick={e => handleClass(e, classType.MECH)}
          >
            <img className="ml-8" src={classIcon.mech} alt="mech" />
          </Checkbox>
          <Checkbox
            text="Dawn"
            isChecked={false}
            onClick={e => handleClass(e, classType.DAWN)}
          >
            <img className="ml-8" src={classIcon.dawn} alt="dawn" />
          </Checkbox>
          <Checkbox
            text="Dusk"
            isChecked={false}
            onClick={e => handleClass(e, classType.DUSK)}
          >
            <img className="ml-8" src={classIcon.dusk} alt="dusk" />
          </Checkbox>
        </div>
      </div>
      <div className="my-8 w-full">
        <p className="text-left text-secondary-text">MYSTIC</p>
        <Range onClickCallback={handleMystic} />
      </div>
      <div className="my-8 w-full">
        <p className="text-left text-secondary-text">PURENESS</p>
        <Range onClickCallback={handlePureness} />
      </div>
      <div className="my-8 w-full">
        <p className="text-left text-secondary-text">OTHER</p>
        <div className="grid grid-cols-2 gap-3 mt-8">
          {/* <Checkbox
            text="Region Japan"
            isChecked={false}
            onClick={e => handleOther(e, otherType.JAPAN)}
          /> */}
          <Checkbox
            text="MEO"
            isChecked={false}
            onClick={e => handleOther(e, otherType.MEO)}
          />
          <Checkbox
            text="MEO II"
            isChecked={false}
            onClick={e => handleOther(e, otherType.MEO2)}
          />
          <Checkbox
            text="Origin"
            isChecked={false}
            onClick={e => handleOther(e, otherType.ORIGIN)}
          />
        </div>
      </div>
    </>
  )
}

export default GeneralFilter
