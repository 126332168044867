import React, { useState } from "react"

//From https://zillow.github.io/react-slider/#reactslider
import ReactSlider from "react-slider"
import styled from "styled-components"

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 16px;
`

const StyledThumb = styled.div`
  height: 16px;
  line-height: 25px;
  width: 16px;
  text-align: center;
  background-color: #92b07b;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`

const Thumb = (props, state) => (
  <StyledThumb {...props}>
    <div className="absolute top-full">{state.valueNow}</div>
  </StyledThumb>
)

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${props =>
    props.index === 2 ? "#35402C" : props.index === 1 ? "#AEE4BA" : "#35402C"};
  border-radius: 999px;
  margin: auto 0;
  height: 4px;
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const MIN = 21
const MAX = 67

const Slider = ({ title, children, onChangeCallback = () => {} }) => {
  const [lowVal, setLowVal] = useState(MIN)
  const [highVal, setHighVal] = useState(MAX)

  const handleReset = () => {
    if (lowVal === MIN && highVal == MAX) {
      //do nothing
      return
    } else {
      setLowVal(MIN)
      setHighVal(MAX)
      onChangeCallback(null)
    }
  }

  const handleAfterChange = value => {
    setLowVal(value[0])
    setHighVal(value[1])

    if (value[0] === 21 && value[1] === 67) {
      onChangeCallback(null)
    } else {
      onChangeCallback([value[0], value[1]])
    }
  }

  return (
    <div className="w-full">
      <div className="flex flex-row my-16">
        {children}
        <p className="ml-6 text-left text-secondary-text flex-grow">{title}</p>
        <p
          onClick={handleReset}
          className="text-left text-axietree-accent cursor-pointer hover:underline"
        >
          Reset
        </p>
      </div>
      <StyledSlider
        renderTrack={Track}
        renderThumb={Thumb}
        min={MIN}
        max={MAX}
        value={[lowVal, highVal]}
        onAfterChange={handleAfterChange}
      />
    </div>
  )
}

export default Slider
