import React, { useState } from "react"
import RangeBtn from "./rangeBtn"

const Range = ({ onClickCallback = () => {} }) => {
  const [mysticFilter, setMysticFilter] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ])

  const handleMysticClick = index => {
    let tmp = [false, false, false, false, false, false]
    tmp[index] = true

    setMysticFilter(tmp)
    onClickCallback(index)
  }

  return (
    <div className="my-8 w-full">
      <div className="flex flex-row relative w-full">
        <div className="relative flex w-full justify-between items-center">
          <div className="absolute w-full h-2px mt-4 bg-filter-range"></div>
          <RangeBtn
            value="Any"
            selected={mysticFilter[0]}
            onClickCallback={() => handleMysticClick(0)}
          />
          <RangeBtn
            value={1}
            selected={mysticFilter[1]}
            onClickCallback={() => handleMysticClick(1)}
          />
          <RangeBtn
            value={2}
            selected={mysticFilter[2]}
            onClickCallback={() => handleMysticClick(2)}
          />
          <RangeBtn
            value={3}
            selected={mysticFilter[3]}
            onClickCallback={() => handleMysticClick(3)}
          />
          <RangeBtn
            value={4}
            selected={mysticFilter[4]}
            onClickCallback={() => handleMysticClick(4)}
          />
          <RangeBtn
            value={5}
            selected={mysticFilter[5]}
            onClickCallback={() => handleMysticClick(5)}
          />
          <RangeBtn
            value={6}
            selected={mysticFilter[6]}
            onClickCallback={() => handleMysticClick(6)}
          />
        </div>
      </div>
      <div className="relative flex w-full mt-12 justify-between items-center">
        <p className="w-8">Any</p>
        <p className="w-8"> 1</p>
        <p className="w-8">2</p>
        <p className="w-8">3</p>
        <p className="w-8">4</p>
        <p className="w-8">5</p>
        <p className="w-8">6</p>
      </div>
    </div>
  )
}

export default Range
