import React, { useState } from "react"

const RangeBtn = ({ value, selected = false, onClickCallback = () => {} }) => {
  if (selected) {
    return (
      <div className="relative w-8 h-12 pr-12 rounded-full bg-transparent transition text-center">
        <div className="w-16 h-16 bg-secondary-text rounded-full"></div>
      </div>
    )
  } else {
    return (
      <div
        className="relative w-8 h-4 rounded-full bg-transparent transition text-center cursor-pointer"
        onClick={onClickCallback}
      >
        <div className="w-8 h-8 bg-filter-range rounded-full"></div>
      </div>
    )
  }
}

export default RangeBtn
