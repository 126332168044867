import React, { useState, useEffect } from "react"
import Slider from "./slider"

import healthIcon from "../../../images/icons/card/health.svg"
import speedIcon from "../../../images/icons/card/speed.svg"
import skillIcon from "../../../images/icons/card/skill.svg"
import moraleIcon from "../../../images/icons/card/morale.svg"

const STAT = {
  HEALTH: 0,
  SPEED: 1,
  SKILL: 2,
  MORALE: 3,
}

const StatsFilter = ({ updateStatsCallback = () => {} }) => {
  //null indicates no filter
  const [statFilterData, setStatFilterData] = useState({
    health: null,
    speed: null,
    skill: null,
    morale: null,
  })

  //Need this so the slider component renders full width
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const handleUpdate = (val, type) => {
    //JSON.part(...) to copy value and force refresh
    let tmp = JSON.parse(JSON.stringify(statFilterData))
    switch (type) {
      case STAT.HEALTH:
        tmp.health = val
        break
      case STAT.SPEED:
        tmp.speed = val
        break
      case STAT.SKILL:
        tmp.skill = val
        break
      case STAT.MORALE:
        tmp.morale = val
        break
    }

    setStatFilterData(tmp)

    //Send filter data to parent
    updateStatsCallback(tmp)
  }

  return (
    <div className="w-full my-8">
      {isLoaded && (
        <>
          <div className="my-32 w-full">
            <Slider
              title="HEALTH"
              onChangeCallback={val => handleUpdate(val, STAT.HEALTH)}
            >
              <img src={healthIcon} alt="health" />
            </Slider>
          </div>
          <div className="my-32">
            <Slider
              title="SPEED"
              onChangeCallback={val => handleUpdate(val, STAT.SPEED)}
            >
              <img src={speedIcon} alt="speed" />
            </Slider>
          </div>
          <div className="my-32">
            <Slider
              title="SKILL"
              onChangeCallback={val => handleUpdate(val, STAT.SKILL)}
            >
              <img src={skillIcon} alt="skill" />
            </Slider>
          </div>
          <div className="my-32">
            <Slider
              title="MORALE"
              onChangeCallback={val => handleUpdate(val, STAT.MORALE)}
            >
              <img src={moraleIcon} alt="morale" />
            </Slider>
          </div>
        </>
      )}
    </div>
  )
}

export default StatsFilter
