import beast from "./images/icons/card/axieTypes/beast.svg"
import aquatic from "./images/icons/card/axieTypes/aquatic.svg"
import plant from "./images/icons/card/axieTypes/plant.svg"
import bird from "./images/icons/card/axieTypes/bird.svg"
import bug from "./images/icons/card/axieTypes/bug.svg"
import reptile from "./images/icons/card/axieTypes/reptile.svg"
import mech from "./images/icons/card/axieTypes/mech.svg"
import dawn from "./images/icons/card/axieTypes/dawn.svg"
import dusk from "./images/icons/card/axieTypes/dusk.svg"

export const classIcon = {
  mech: mech,
  bug: bug,
  beast: beast,
  plant: plant,
  dusk: dusk,
  aquatic: aquatic,
  bird: bird,
  reptile: reptile,
  dawn: dawn,
}

export const classColor = {
  hidden_1: "axie-type-mech",
  bug: "axie-type-bug",
  beast: "axie-type-beast",
  plant: "axie-type-plant",
  hidden_3: "axie-type-dusk",
  aquatic: "axie-type-aquatic",
  bird: "axie-type-bird",
  reptile: "axie-type-reptile",
  hidden_2: "axie-type-dawn",
  mystic: "axie-type-mystic",
}

export const LendPriceOption = Object.freeze({ SLP: "% SLP", ETH: "ETH" })

export const LendTimeOption = Object.freeze({ DAY: "Day(s)", WEEK: "Week(s)" })

//Returns single object given an axie and market data array
export const getMarketData = (axie, marketData) => {
  let id = axie.id
  for (var i = 0; i < marketData.length; i++) {
    if (parseInt(marketData[i].id) === id) {
      return marketData[i]
    }
  }

  return null
}

export const getMysticLevel = axie => {
  let mysticLevel = 0
  axie.parts.map(p => {
    if (p.mystic) {
      mysticLevel++
    }
  })

  return mysticLevel
}

export const parsePrice = marketData => {
  if (marketData.eth > 0) {
    return `${marketData.eth} ETH`
  } else {
    return `${marketData.slp}% SLP`
  }
}

export const parseDuration = marketData => {
  let isWeek = marketData.duration % 604800 === 0
  if (isWeek) {
    return `${marketData.duration / 604800} Week(s)`
  } else {
    return `${marketData.duration / 86400} Day(s)`
  }
}

export const getTransactionUrl = (hash, networkId) => {
  let url
  if (networkId === 1) {
    url = `https://etherscan.io/tx/${hash}`
  } else {
    url = `https://etherscan.io/tx/${hash}`
  }

  return url
}

//TODO: clean this up but this works
export const isFilterDisplay = (filter, axieData, marketData) => {
  //get market data (eth/slp lend, duration, etc...) from SC data
  let thisAxieMarketData = getMarketData(axieData, marketData)

  if (axieData.id === 160024) {
    console.log("hi!")
  }

  //1) GENERAL FILTER
  let generalFilter = true
  if (filter.general) {
    //check if the general filter exists
    let generalFilterObj = filter.general

    //borrow type
    let borrowFilter = false
    if (generalFilterObj.borrow) {
      if (!generalFilterObj.borrow.eth && !generalFilterObj.borrow.slp) {
        //If both unselected
        borrowFilter = true
      } else if (generalFilterObj.borrow.eth && thisAxieMarketData.eth > 0) {
        //Axie is eth lend if eth greater than 0, slp lend has eth === 0
        borrowFilter = true
      } else if (generalFilterObj.borrow.slp && thisAxieMarketData.eth <= 0) {
        borrowFilter = true
      } else {
        borrowFilter = false
      }
    }

    //class
    let classFilter = false
    if (generalFilterObj.class) {
      //If none selected return true
      if (
        !generalFilterObj.class.beast &&
        !generalFilterObj.class.aquatic &&
        !generalFilterObj.class.plant &&
        !generalFilterObj.class.bird &&
        !generalFilterObj.class.bug &&
        !generalFilterObj.class.reptile &&
        !generalFilterObj.class.mech &&
        !generalFilterObj.class.dawn &&
        !generalFilterObj.class.dusk
      ) {
        classFilter = true
      } else {
        let thisAxieClass = axieData.class
        //Check if it hits one class selected return true
        if (thisAxieClass === "beast" && generalFilterObj.class.beast) {
          classFilter = true
        } else if (
          thisAxieClass === "aquatic" &&
          generalFilterObj.class.aquatic
        ) {
          classFilter = true
        } else if (thisAxieClass === "plant" && generalFilterObj.class.plant) {
          classFilter = true
        } else if (thisAxieClass === "bird" && generalFilterObj.class.bird) {
          classFilter = true
        } else if (thisAxieClass === "bug" && generalFilterObj.class.bug) {
          classFilter = true
        } else if (
          thisAxieClass === "reptile" &&
          generalFilterObj.class.reptile
        ) {
          classFilter = true
        } else if (
          thisAxieClass === "hidden_1" &&
          generalFilterObj.class.mech
        ) {
          classFilter = true
        } else if (
          thisAxieClass === "hidden_2" &&
          generalFilterObj.class.dawn
        ) {
          classFilter = true
        } else if (
          thisAxieClass === "hidden_3" &&
          generalFilterObj.class.dusk
        ) {
          classFilter = true
        }
      }
    }

    //mystic
    let mysticFilter = true
    if (generalFilterObj.mystic) {
      let parts = axieData.parts

      let mysticLevel = 0
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].mystic) {
          mysticLevel++
        }
      }

      mysticFilter = mysticLevel >= generalFilterObj.mystic
    }

    //pureness
    let purenessFilter = true
    if (generalFilterObj.pureness) {
      let parts = axieData.parts

      let purenessLevel = 0
      for (let i = 0; i < parts.length; i++) {
        console.log(parts[i].class)
        if (parts[i].class === axieData.class) {
          purenessLevel++
        }
      }

      console.log(`${axieData.class} pureness ${purenessLevel}`)
      purenessFilter = purenessLevel >= generalFilterObj.pureness
    }

    let otherFilter = false
    if (generalFilterObj.other) {
      if (
        !generalFilterObj.other.japan &&
        !generalFilterObj.other.meo &&
        !generalFilterObj.other.meo2 &&
        !generalFilterObj.other.origin
      ) {
        otherFilter = true
      } else {
        if (generalFilterObj.other.meo && axieData.title === "MEO Corp") {
          otherFilter = true
        } else if (
          generalFilterObj.other.meo2 &&
          axieData.title === "MEO Corp II"
        ) {
          otherFilter = true
        } else if (
          generalFilterObj.other.origin &&
          axieData.title === "Origin"
        ) {
          otherFilter = true
        }
      }
    }

    generalFilter =
      borrowFilter &&
      classFilter &&
      mysticFilter &&
      purenessFilter &&
      otherFilter
    // console.log(`borrow ${borrowFilter}`)
    // console.log(`class ${classFilter}`)
    // console.log(`mystic ${mysticFilter}`)
  }

  //2) PARTS FILTER
  //TODO: PARTS DOING LATER

  //3) STATS FILTER
  let statsFilter = false
  if (filter.stat) {
    let statFilterObj = filter.stat
    console.log(statFilterObj)

    let axieStats = axieData.stats
    statsFilter =
      (!statFilterObj.health ||
        (statFilterObj.health &&
          statFilterObj.health[0] <= axieStats.hp &&
          axieStats.hp <= statFilterObj.health[1])) &&
      (!statFilterObj.speed ||
        (statFilterObj.speed &&
          statFilterObj.speed[0] <= axieStats.speed &&
          axieStats.speed <= statFilterObj.speed[1])) &&
      (!statFilterObj.skill ||
        (statFilterObj.skill &&
          statFilterObj.skill[0] <= axieStats.skill &&
          axieStats.skill <= statFilterObj.skill[1])) &&
      (!statFilterObj.morale ||
        (statFilterObj.morale &&
          statFilterObj.morale[0] <= axieStats.morale &&
          axieStats.morale <= statFilterObj.morale[1]))
  } else {
    //if blank
    statsFilter = true
  }

  return generalFilter && statsFilter
}

// //Formats data for table view
// const formatTableData = data => {
//   let fmtData = []
//   data.map(axie => {
//     let d = {
//       axie: axie.image,
//       nameClass: {
//         name: axie.id,
//         class: axie.name,
//       },
//       winRate: "40%",
//       borrowCount: "3",
//       fee: "25% SLP",
//       earnings: "12.5 SLP",
//       lifetimeEarnings: {
//         eth: 1,
//         slp: 12.5,
//       },
//       loanStatus: 0,
//     }

//     fmtData.push(d)
//   })

//   return fmtData
// }
